import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Replace with your actual EmailJS and Stripe credentials
const EMAILJS_USER_ID = '4bH4AWhWPAKEmWvmQ';
const EMAILJS_SERVICE_ID = 'service_7qsg1lj';
const EMAILJS_TEMPLATE_ID = 'your_emailjs_template_id';
const STRIPE_PUBLIC_KEY = 'your_stripe_public_key';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const PaymentForm = ({ onSubmit, isSubmitting, handleBypass }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    onSubmit(e, { stripe, elements });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-6">
        <label className="block mb-2 font-bold">Card Details</label>
        <div className="p-3 border rounded">
          <CardElement options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }} />
        </div>
      </div>
      <div className="flex space-x-4">
        <button
          type="submit"
          className="button-accept"
          disabled={!stripe || isSubmitting}
        >
          {isSubmitting ? 'Processing...' : 'Submit and Pay'}
        </button>
        <button
          type="button"
          onClick={handleBypass}
          className="button-warn"
        >
          Bypass Payment (Demo)
        </button>
      </div>
    </form>
  );
};

function Book() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    fitnessGoals: '',
    currentFitnessLevel: '',
    dietaryRestrictions: '',
    medicalConditions: '',
    preferredWorkoutDays: [],
    additionalNotes: ''
  });
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = [
    'Personal Information',
    'Fitness Profile',
    'Health Information',
    'Preferences',
    'Review and Payment'
  ];

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      const updatedWorkoutDays = checked
        ? [...formData.preferredWorkoutDays, value]
        : formData.preferredWorkoutDays.filter(day => day !== value);
      setFormData({ ...formData, preferredWorkoutDays: updatedWorkoutDays });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleNextStep = () => {
    const validationResult = validateStep();
    if (validationResult === true) {
      setCurrentStep(currentStep + 1);
    } else {
      toast.error(validationResult);
    }
  };

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateStep = () => {
    switch (currentStep) {
      case 0:
        if (!formData.name) return "Please enter your name.";
        if (!formData.email) return "Please enter your email.";
        if (!/^\S+@\S+\.\S+$/.test(formData.email)) return "Please enter a valid email address.";
        if (!formData.phone) return "Please enter your phone number.";
        if (!/^(07\d{9})$/.test(formData.phone)) return "Please enter a valid UK mobile number starting with '07' (11 digits).";
        return true;
      case 1:
        if (!formData.fitnessGoals) return "Please enter your fitness goals.";
        if (!formData.currentFitnessLevel) return "Please select your current fitness level.";
        return true;
      case 2:
        return true; // These fields are optional
      case 3:
        if (formData.preferredWorkoutDays.length === 0) return "Please select at least one preferred workout day.";
        return true;
      default:
        return true;
    }
  };

  const handleSubmit = async (e, { stripe, elements } = {}) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    // Final validation
    for (let i = 0; i < steps.length; i++) {
      const validationResult = validateStep(i);
      if (validationResult !== true) {
        toast.error(`Step ${i + 1}: ${validationResult}`);
        setIsSubmitting(false);
        return;
      }
    }

    try {
      // Send email using EmailJS
      await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        {
          ...formData,
          preferredWorkoutDays: formData.preferredWorkoutDays.join(', ')
        },
        EMAILJS_USER_ID
      );

      if (stripe && elements) {
        const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
          },
        });

        if (paymentError) {
          throw new Error(paymentError.message);
        }

        // Here you would typically send the paymentMethod.id to your backend
        // to complete the payment. For demo purposes, we'll just proceed.
        console.log('Payment Method:', paymentMethod);
        navigate('/thank-you');
      }

    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
      toast.error('An error occurred while processing your booking. Please try again.');
      setIsSubmitting(false);
    }
  };

  const handleBypass = () => {
    navigate('/thank-you');
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Personal Information</h2>
            <div className="mb-4">
              <label htmlFor="name" className="block mb-2 font-bold">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 font-bold">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block mb-2 font-bold">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                placeholder="07xxxxxxxxx"
                required
              />
              <p className="text-sm text-gray-600 mt-1">Enter a UK mobile number starting with '07' (11 digits)</p>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Fitness Profile</h2>
            <div className="mb-4">
              <label htmlFor="fitnessGoals" className="block mb-2 font-bold">Fitness Goals</label>
              <textarea
                id="fitnessGoals"
                name="fitnessGoals"
                value={formData.fitnessGoals}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                required
              ></textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="currentFitnessLevel" className="block mb-2 font-bold">Current Fitness Level</label>
              <select
                id="currentFitnessLevel"
                name="currentFitnessLevel"
                value={formData.currentFitnessLevel}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
                required
              >
                <option value="">Select your fitness level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Health Information</h2>
            <div className="mb-4">
              <label htmlFor="dietaryRestrictions" className="block mb-2 font-bold">Dietary Restrictions</label>
              <textarea
                id="dietaryRestrictions"
                name="dietaryRestrictions"
                value={formData.dietaryRestrictions}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              ></textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="medicalConditions" className="block mb-2 font-bold">Medical Conditions</label>
              <textarea
                id="medicalConditions"
                name="medicalConditions"
                value={formData.medicalConditions}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              ></textarea>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Preferences</h2>
            <div className="mb-4">
              <label className="block mb-2 font-bold">Preferred Workout Days</label>
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                <label key={day} className="inline-flex items-center mr-4 mb-2">
                  <input
                    type="checkbox"
                    name="preferredWorkoutDays"
                    value={day}
                    checked={formData.preferredWorkoutDays.includes(day)}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  {day}
                </label>
              ))}
            </div>
            <div className="mb-4">
              <label htmlFor="additionalNotes" className="block mb-2 font-bold">Additional Notes</label>
              <textarea
                id="additionalNotes"
                name="additionalNotes"
                value={formData.additionalNotes}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              ></textarea>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Review and Payment</h2>
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-2">Summary</h3>
              <p><strong>Name:</strong> {formData.name}</p>
              <p><strong>Email:</strong> {formData.email}</p>
              <p><strong>Phone:</strong> {formData.phone}</p>
              <p><strong>Fitness Goals:</strong> {formData.fitnessGoals}</p>
              <p><strong>Current Fitness Level:</strong> {formData.currentFitnessLevel}</p>
              <p><strong>Dietary Restrictions:</strong> {formData.dietaryRestrictions || 'None'}</p>
              <p><strong>Medical Conditions:</strong> {formData.medicalConditions || 'None'}</p>
              <p><strong>Preferred Workout Days:</strong> {formData.preferredWorkoutDays.join(', ')}</p>
              <p><strong>Additional Notes:</strong> {formData.additionalNotes || 'None'}</p>
            </div>
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-2">Payment</h3>
              <p>Consultation Fee: £50</p>
            </div>
            <Elements stripe={stripePromise}>
              <PaymentForm 
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                handleBypass={handleBypass}
              />
            </Elements>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 mt-24 py-8">
      <h1 className="text-3xl font-bold mb-6">Book a Fitness Consultation</h1>
      <div className="mb-6">
        {steps.map((step, index) => (
          <span
            key={index}
            className={`inline-block mr-2 px-3 py-1 rounded-full font-bold ${
              index === currentStep ? 'bg-blue-500 text-white' : 'bg-gray-200'
            }`}
          >
            {index + 1}
          </span>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {renderStep()}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mt-6">
          {currentStep > 0 && (
            <button
              type="button"
              onClick={handlePrevStep}
              className="button-decline"
            >
              Previous
            </button>
          )}
          {currentStep < steps.length - 1 && (
            <button
              type="button"
              onClick={handleNextStep}
              className="button-accept"
            >
              Next
            </button>
          )}
        </div>
      </form>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default Book;
