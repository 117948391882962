import React from 'react';
import { Link } from 'react-router-dom';

function ThankYou() {
  return (
    <div className="container mx-auto px-4 py-8 mt-24 text-center">
      <h1 className="text-3xl font-bold mb-6">Thank You for Booking!</h1>
      <p className="mb-4">Your fitness consultation has been successfully booked and paid for.</p>
      <p className="mb-4">We'll be in touch shortly with further details about your consultation.</p>
      
      <div className="my-8">
        <h2 className="text-2xl font-bold mb-4">Schedule Your Call with Alex</h2>
        <p className="mb-4">Click the button below to schedule your call with Alex, your Personal Trainer:</p>
        <a 
          href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1GHnsYAY5vaa2_UjkTrKT4YlHKaJ9RgOVGBklxPuDoW1B8FgdpEhXlPJ0cc4WjjEC29o7o984t"
          target="_blank"
          rel="noopener noreferrer"
          className="button-accept"
        >
          Schedule Your Call
        </a>
      </div>
      
      <Link to="/" className="button-decline">
        Return to Home
      </Link>
    </div>
  );
}

export default ThankYou;
